import { decodeJwt } from 'jose'
import type { RouteLocationRaw } from 'vue-router'
import { reactive, computed } from 'vue'
import type { User } from './user'

export interface Login {
  email: string
  password: string
}

export interface TokenResponse {
  accessToken: string
  refreshToken?: string
}

let refreshTokenPromise: Promise<string | null> | null

export class RefreshTokenError extends Error { }

const state = reactive({
  isLoggedIn: false,
  user: null as User | null,
  accessToken: null as string | null,
  accessTokenExp: null as Date | null,
  refreshToken: null as string | null,
  refreshTokenExp: null as Date | null,
})

const AuthEventTarget = useEventTarget<{ login: Event, logout: Event }>()

export function useAuth() {
  async function setRefreshToken(refreshToken: string | null) {
    state.refreshToken = refreshToken
    if (refreshToken) {
      const refreshTokenDecoded = await decodeJwt(refreshToken)
      state.refreshTokenExp = refreshTokenDecoded.exp ? new Date(refreshTokenDecoded.exp * 1000) : null
    }
    else {
      state.refreshTokenExp = null
    }
  }

  async function refreshTokens() {
    const tokens = await useAttainApi<TokenResponse>(`/auth/token`, {
      method: 'POST',
      body: { refreshToken: state.refreshToken },
      authorization: false,
    })
    await setAccessToken(tokens.accessToken)
    useCookie('accessToken', { maxAge: 60 * 60 * 24 * 7 }).value = tokens.accessToken
    if (tokens.refreshToken) {
      await setRefreshToken(tokens.refreshToken)
      useCookie('refreshToken', { maxAge: 60 * 60 * 24 * 7 }).value = tokens.refreshToken
    }
    return tokens
  }

  async function setAccessToken(accessToken: string | null) {
    state.accessToken = accessToken
    if (accessToken) {
      const accessTokenDecoded = await decodeJwt(accessToken)
      state.accessTokenExp = accessTokenDecoded.exp ? new Date(accessTokenDecoded.exp * 1000) : null
      state.isLoggedIn = true
    }
    else {
      state.accessTokenExp = null
      state.isLoggedIn = false
    }
  }

  async function getAccessToken() {
    if (refreshTokenPromise) return refreshTokenPromise
    if (!state.accessToken) return null
    if (!isAccessTokenExpired()) return state.accessToken
    if (isRefreshTokenExpired()) throw new RefreshTokenError('Unable to refresh tokens.', { cause: 'The refreshToken is expired' })
    refreshTokenPromise = new Promise((resolve, reject) => {
      refreshTokens()
        .then(response => resolve(response.accessToken))
        .catch(err => reject(new RefreshTokenError('Unable to refresh tokens.', { cause: err })))
        .finally(() => refreshTokenPromise = null)
    })
    return refreshTokenPromise
  }

  async function login(login: Login, options?: { fetchUser?: boolean, remember?: boolean, redirectTo?: RouteLocationRaw }) {
    const { accessToken, refreshToken = null } = await useAttainApi<TokenResponse>(`/auth/signin`, {
      method: 'POST',
      body: login,
      authorization: false,
    })
    const config = useAttainConfig()
    if (options?.remember ?? config.remember) {
      useCookie('accessToken', { maxAge: 60 * 60 * 24 * 7 }).value = accessToken
      useCookie('refreshToken', { maxAge: 60 * 60 * 24 * 7 }).value = refreshToken
    }
    await setAccessToken(accessToken)
    if (refreshToken) await setRefreshToken(refreshToken)
    if (options?.fetchUser ?? config.fetchUser) await fetchUser()

    AuthEventTarget.dispatchEvent('login')

    if (options?.redirectTo) useRouter().replace(options.redirectTo)
  }

  function logout(options?: { redirectTo?: RouteLocationRaw }) {
    setUser(null)
    setAccessToken(null)
    setRefreshToken(null)
    useCookie('accessToken').value = null
    useCookie('refreshToken').value = null

    AuthEventTarget.dispatchEvent('logout')

    useRouter().push(options?.redirectTo ?? useAttainConfig().locations.home)
  }

  async function fetchUser() {
    const user = await useAttainApi<User>('/accounts/me', { method: 'GET' })
    setUser(user)
    return user
  }

  function setUser(user: User | null) {
    state.user = user
  }

  async function restoreFromCookie() {
    await Promise.all([updateAccessTokenFromCookie(), updateRefreshTokenFromCookie()])
    if (useAttainConfig().fetchUser && state.isLoggedIn) {
      await fetchUser()
      AuthEventTarget.dispatchEvent('login')
    }
  }

  async function updateAccessTokenFromCookie() {
    const accessToken = useCookie('accessToken').value ?? null
    await setAccessToken(accessToken)
  }

  async function updateRefreshTokenFromCookie() {
    const accessToken = useCookie('refreshToken').value ?? null
    await setRefreshToken(accessToken)
  }

  function isAccessTokenExpired(): boolean {
    if (!state.accessToken) return true
    if (state.accessTokenExp instanceof Date) return state.accessTokenExp.getTime() <= Date.now()
    return false
  }

  function isRefreshTokenExpired(): boolean {
    if (!state.refreshToken) return true
    if (state.refreshTokenExp instanceof Date) return state.refreshTokenExp.getTime() <= Date.now()
    return false
  }

  function hasRole(role: UserRole | UserRole[]) {
    const roles = state.user?.roles ?? []
    if (Array.isArray(role)) {
      for (const oneOfRole of role) {
        if (roles.includes(oneOfRole)) return true
      }
      return false
    }
    return roles.includes(role)
  }

  return {
    login,
    logout,
    setAccessToken,
    getAccessToken,
    isAccessTokenExpired,
    refreshTokens,
    setRefreshToken,
    isRefreshTokenExpired,
    restoreFromCookie,
    fetchUser,
    setUser,
    state: readonly(state),
    user: computed(() => state.user),
    isLoggedIn: computed(() => state.isLoggedIn),
    hasRole,
    addEventListener: AuthEventTarget.addEventListener,
    removeEventListener: AuthEventTarget.removeEventListener,
  }
}
